import React from 'react';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { makeStyles } from '@material-ui/core/styles';
import { CountryData } from './interfaces/CountryData.interface';
import { ContinentData } from './interfaces/ContinentData.interface';
import { ContinentDataUtils } from '../../../../../../commons/utils/continent-data.utils';

const useStyles = makeStyles(() => ({
  continentContainer: {
    marginTop: '30px',
  },
  continentLabel: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  countryLabel: {
    fontWeight: 600,
  },
}));

interface QuickCreateUpdatePackageStep1Props {
  selectedIsoCodes?: string[];
  isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[];
  onNext: Function;
  onCancel: Function;
}

export default function QuickCreateUpdatePackageStep1(props: QuickCreateUpdatePackageStep1Props) {
  const [continents, setContinents] = React.useState<ContinentData[]>([]);
  const [selectedIsoCodeSet, setSelectedIsoCodeSet] = React.useState<Set<string>>(new Set<string>());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const initData = async (isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[]) => {
    setIsLoading(true);

    const tmpContinents = ContinentDataUtils.generateContinentData(isoCodeWithCountryCodes);

    setContinents(tmpContinents);

    setIsLoading(false);
  };

  React.useEffect(() => {
    initData(props.isoCodeWithCountryCodes);

    const newSet = new Set<string>();

    props?.selectedIsoCodes?.forEach(i => newSet.add(i));

    setSelectedIsoCodeSet(newSet);
  }, [props]);

  const selectCountryHandler = (country: CountryData) => {
    const isoCode = country?.phoneData?.isoCode;

    const copiedSet = new Set<string>(selectedIsoCodeSet);

    if (isSelectedCountry(country)) {
      copiedSet.delete(isoCode);
    } else {
      copiedSet.add(isoCode);
    }

    setSelectedIsoCodeSet(copiedSet);
  };

  const isSelectedCountry = (country: CountryData) => {
    const isoCode = country?.phoneData?.isoCode;
    return selectedIsoCodeSet.has(isoCode);
  };

  const selectedContinentHandler = (continent: ContinentData) => {
    const copiedSet = new Set<string>(selectedIsoCodeSet);

    if (isSelectedContinent(continent)) {
      continent?.countries?.forEach(c => copiedSet.delete(c.phoneData?.isoCode));
    } else {
      continent?.countries?.forEach(c => copiedSet.add(c.phoneData?.isoCode));
    }

    setSelectedIsoCodeSet(copiedSet);
  };

  const isSelectedContinent = (continent: ContinentData) => {
    const isAnyCountryNotSelected = continent?.countries?.find(c => !isSelectedCountry(c));
    return !isAnyCountryNotSelected;
  };

  const isAbleToNext = () => {
    return selectedIsoCodeSet.size > 0;
  };

  const submitHandler = (event: any) => {
    event.preventDefault();

    if (!isAbleToNext()) {
      return;
    }

    const selectedIsoCodes = Array.from(selectedIsoCodeSet.values());

    props.onNext(selectedIsoCodes);
  };

  const classes = useStyles();

  return (
    <>
      {continents?.map(continent => (
        <div key={continent.name} className={classes.continentContainer}>
          <div className={classes.flexRow} onClick={() => selectedContinentHandler(continent)}>
            {isSelectedContinent(continent) ? (
              <CheckBoxIcon color="primary" />
            ) : (
              <CheckBoxOutlineBlankIcon color="primary" />
            )}
            <div className={classes.continentLabel}>{continent.name}</div>
          </div>

          <Grid container spacing={1} style={{ marginTop: '10px' }}>
            {continent.countries.map(country => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                key={country?.phoneData?.isoCode}
                onClick={() => selectCountryHandler(country)}
              >
                <div className={classes.flexRow}>
                  {isSelectedCountry(country) ? (
                    <CheckBoxIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon color="primary" />
                  )}
                  <div>
                    <div className={classes.countryLabel}>{country.info?.country}</div>
                    <div>
                      [{country?.phoneData?.isoCode}] [{country?.phoneData?.phoneCountryCode}]
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}

      {isLoading ? <LinearProgress /> : <></>}

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={isLoading} onClick={() => props.onCancel()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading || !isAbleToNext()}
            onClick={submitHandler}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
