import React from 'react';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { DEFAULT_PROVIDER_OPTIONS } from '../../../../../../models/consts/DefaultProviderOptions.const';
import { CommunicationUnitPackage } from '../../../../../../models/interfaces/CommunicationUnitPackage.interface';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import smsService from '../../../../../../services/sms.service';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import { COMMUNICATION_UNIT_PACKAGE_VISIBILITY } from '../../../../../../models/enums/CommunicationUnitPackageVisibility.enum';
import { CommunicationUnitPrice } from '../../../../../../models/interfaces/CommunicationUnitPrice.interface';
import { CreditPriceCalculatorUtils } from '../../../../../../commons/utils/credit-price-calculator.utils';

interface CreditPackageDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  creditPackage?: CommunicationUnitPackage;
  onClose: Function;
  onDelete?: Function;
  onUpdate?: Function;
  onCreate?: Function;
}

const packageVisibilityOptions = convertEnumToArray(COMMUNICATION_UNIT_PACKAGE_VISIBILITY);

export default function CreditPackageDialogComponent(props: CreditPackageDialogComponentProps) {
  const [isoCodeWithCountryCodes, setIsoCodeWithCountryCodes] = React.useState<IsoCountryCodeWithPhoneCountryCode[]>(
    []
  );
  const [creditPackage, setCreditPackage] = React.useState<CommunicationUnitPackage>(null);
  const [creditUnitPrices, setCreditUnitPrices] = React.useState<CommunicationUnitPrice[]>([]);

  const _fetchIsoCodeWithCountryCodes = async () => {
    const response = await smsService.getAllCountryCodes();
    setIsoCodeWithCountryCodes(response);
  };

  const _fetchAllCommunicationUnitPrice = async () => {
    const response = await smsService.getAllCommunicationUnitPrices();
    setCreditUnitPrices(response);
  };

  React.useEffect(() => {
    _fetchIsoCodeWithCountryCodes();
    _fetchAllCommunicationUnitPrice();
  }, []);

  React.useEffect(() => {
    setCreditPackage(props.creditPackage);
  }, [props]);

  const dataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    const creditPackageToUpdate = {
      ...(creditPackage || {}),
      [name]: type === 'checkbox' ? checked : value,
    };

    setCreditPackage(
      CreditPriceCalculatorUtils.calculateEuroPriceOfEmailAndSmsForCreditPackage(
        creditPackageToUpdate,
        creditUnitPrices
      )
    );
  };

  const getTitle = () => {
    const p = props.creditPackage;

    if (p) {
      return `[${p.countryCode} | ${p.provider}] ${p.balance} Credit with price ${p.price} [id=${p.id}]`;
    } else {
      return 'Add a new Credit package';
    }
  };

  const getContent = () => {
    return (
      <>
        {/*ID*/}
        {creditPackage?.id ? (
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            fullWidth
            key="id"
            label="ID"
            name="id"
            value={creditPackage?.id}
            disabled={true}
          />
        ) : (
          <></>
        )}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Locale*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Locale"
              name="countryCode"
              value={creditPackage?.countryCode || ''}
              disabled={!!creditPackage?.id}
              onChange={dataChangeHandler}
            >
              {isoCodeWithCountryCodes.map(item => (
                <MenuItem value={item.isoCode} key={item.isoCode}>
                  {item.isoCode} ({item.phoneCountryCode})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            {/*Provider*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Provider"
              name="provider"
              value={creditPackage?.provider || ''}
              disabled={!!creditPackage?.id}
              onChange={dataChangeHandler}
            >
              {DEFAULT_PROVIDER_OPTIONS.map(provider => (
                <MenuItem value={provider.value} key={provider.value}>
                  {provider.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Number of Credit*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              label="Number of Credit"
              name="balance"
              value={creditPackage?.balance || ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">Credit</InputAdornment>,
              }}
              onChange={dataChangeHandler}
            />
          </Grid>
          <Grid item xs={6}>
            {/*Price*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              label="Price"
              name="price"
              value={creditPackage?.price || ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
              onChange={dataChangeHandler}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Estimated sms price*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              disabled={true}
              label="Price per SMS"
              name="smsPrice"
              value={creditPackage?.smsPrice || ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
              helperText={<span>To display on pricing page</span>}
            />
          </Grid>
          <Grid item xs={6}>
            {/*Estimated email price*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              disabled={true}
              label="Price per Email"
              name="emailPrice"
              value={creditPackage?.emailPrice || ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
              helperText={<span>To display on pricing page</span>}
            />
          </Grid>
        </Grid>

        <TextField
          variant="outlined"
          margin="dense"
          select
          required
          fullWidth
          label="Visibility"
          name="visibility"
          value={creditPackage?.visibility || ''}
          onChange={dataChangeHandler}
        >
          {packageVisibilityOptions.map(visibility => (
            <MenuItem value={visibility} key={visibility}>
              {visibility}
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  };

  const getButtons = () => {
    return props.creditPackage ? (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={props.isLoading}
            onClick={() => props.onDelete(props.creditPackage)}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading}
            onClick={() => props.onUpdate(creditPackage)}
          >
            Update
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading}
            onClick={() => props.onCreate(creditPackage)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
