import React from 'react';
import { Button, Grid, InputAdornment, LinearProgress, TextField } from '@material-ui/core';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import { SMS_PROVIDER } from '../../../../../../models/enums/SmsProvider.enum';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import { ContinentDataUtils } from '../../../../../../commons/utils/continent-data.utils';
import { ContinentData } from './interfaces/ContinentData.interface';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { CountryData } from './interfaces/CountryData.interface';
import { EurPriceOfProvider } from './interfaces/EurPriceOfProvider.interface';
import { makeStyles } from '@material-ui/core/styles';
import logger from '../../../../../../commons/logger';
import smsProviderService from '../../../../../../services/sms-provider.service';
import smsService from '../../../../../../services/sms.service';

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 'bold',
  },
  dashedBorderBottom: {
    borderBottom: '1px dashed lightgrey',
  },
}));

interface QuickCreateUpdatePackageStep2Props {
  mapOfIsoCodeAndEurPriceOfProvider?: Map<string, EurPriceOfProvider[]>;
  selectedIsoCodes: string[];
  isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[];
  onUpdateEurPriceOfProvider: Function;
  onNext: Function;
  onBack: Function;
}

interface ProviderPriceInEurInputRowProps {
  eurPriceOfProviderList?: EurPriceOfProvider[];
  countryData: CountryData;
  onDataChange: Function;
}

const LIST_OF_SMS_PROVIDERS: string[] = convertEnumToArray(SMS_PROVIDER);

const calculateColumnWidth = (isFirstColumn: boolean): GridSize => {
  const roundedWidth = Math.floor(12 / (LIST_OF_SMS_PROVIDERS.length + 1));

  if (isFirstColumn) {
    return (12 - roundedWidth * LIST_OF_SMS_PROVIDERS.length) as GridSize;
  } else {
    return roundedWidth as GridSize;
  }
};

function ProviderPriceInEurInputRow(props: ProviderPriceInEurInputRowProps) {
  const [eurPriceOfProviderList, setEurPriceOfProviderList] = React.useState<EurPriceOfProvider[]>(
    LIST_OF_SMS_PROVIDERS?.map(
      p =>
        ({
          provider: p,
          eurPricePerSms: null,
        } as EurPriceOfProvider)
    )
  );

  React.useEffect(() => {
    if (props?.eurPriceOfProviderList) {
      const tmpEurPriceOfProviderList = [...(eurPriceOfProviderList || [])];

      let isPriceChanged = false;

      props?.eurPriceOfProviderList?.forEach(p => {
        tmpEurPriceOfProviderList?.forEach(tmpP => {
          if (tmpP.provider === p.provider && tmpP.eurPricePerSms !== p.eurPricePerSms) {
            tmpP.eurPricePerSms = p.eurPricePerSms;
            isPriceChanged = true;
          }
        });
      });

      // must use this check to avoid endless loop of lifecycle update
      if (isPriceChanged) {
        setEurPriceOfProviderList(tmpEurPriceOfProviderList);
      }
    }
  }, [props]);

  React.useEffect(() => {
    props.onDataChange(eurPriceOfProviderList);
  }, [eurPriceOfProviderList]);

  const priceOfProviderChangeHandler = (value: any, index: number) => {
    const tmpEurPriceOfProviderList = [...(eurPriceOfProviderList || [])];
    tmpEurPriceOfProviderList[index].eurPricePerSms = value;
    setEurPriceOfProviderList(tmpEurPriceOfProviderList);
  };

  const classes = useStyles();

  return (
    <>
      <Grid item xs={calculateColumnWidth(true)} className={classes.dashedBorderBottom}>
        <div>{props?.countryData?.info?.country}</div>
        <div>
          [{props?.countryData?.phoneData?.isoCode}] [{props?.countryData?.phoneData?.phoneCountryCode}]
        </div>
      </Grid>
      {eurPriceOfProviderList.map((p, index) => (
        <Grid item xs={calculateColumnWidth(false)} key={index + '-input'} className={classes.dashedBorderBottom}>
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            fullWidth
            label="SMS price"
            value={p.eurPricePerSms || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
            }}
            onChange={event => priceOfProviderChangeHandler(event.target.value, index)}
          />
        </Grid>
      ))}
    </>
  );
}

export default function QuickCreateUpdatePackageStep2(props: QuickCreateUpdatePackageStep2Props) {
  const [continents, setContinents] = React.useState<ContinentData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const initData = async (
    selectedIsoCodes: string[],
    isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[]
  ) => {
    setIsLoading(true);

    const tmpContinents = ContinentDataUtils.generateContinentData(
      isoCodeWithCountryCodes?.filter(i => selectedIsoCodes.includes(i.isoCode))
    );

    setContinents(tmpContinents);

    setIsLoading(false);
  };

  React.useEffect(() => {
    initData(props.selectedIsoCodes, props.isoCodeWithCountryCodes);
  }, [props.selectedIsoCodes, props.isoCodeWithCountryCodes]);

  const providerPriceChangeHandler = (isoCode: string, data: EurPriceOfProvider[]) => {
    const tmpMap = new Map<string, EurPriceOfProvider[]>(props.mapOfIsoCodeAndEurPriceOfProvider);
    tmpMap.set(isoCode, data);
    props.onUpdateEurPriceOfProvider(tmpMap);
  };

  const updateMapOfIsoCodeAndEurPriceOfProvider = (
    mapOfIsoCodeAndNewPrice: Map<string, number>,
    provider: SMS_PROVIDER
  ) => {
    const tmpMap = new Map<string, EurPriceOfProvider[]>(props.mapOfIsoCodeAndEurPriceOfProvider);

    mapOfIsoCodeAndNewPrice?.forEach((value, key) => {
      const currentPriceData = tmpMap.get(key);

      const newPriceData = {
        provider: provider,
        eurPricePerSms: value,
      };

      const isCurrentPriceDataContainProvider = !!currentPriceData?.find(p => p.provider === provider);

      if (!isCurrentPriceDataContainProvider) {
        tmpMap.set(key, [...(currentPriceData || []), newPriceData]);
      } else {
        tmpMap.set(
          key,
          currentPriceData?.map(p => (p.provider === provider ? newPriceData : p))
        );
      }
    });

    props.onUpdateEurPriceOfProvider(tmpMap);
  };

  const autoFillGatewayApiPrices = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const listOfIsoCodeToUpdate: string[] = [];
      continents?.forEach(continent => {
        continent?.countries?.forEach(c => listOfIsoCodeToUpdate.push(c.phoneData?.isoCode));
      });

      const data = await smsService.getGatewayApiPrices(listOfIsoCodeToUpdate);
      updateMapOfIsoCodeAndEurPriceOfProvider(data, SMS_PROVIDER.GATEWAY_API);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const autoFillBulkgatePrices = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const listOfIsoCodeToUpdate: string[] = [];
      continents?.forEach(continent => {
        continent?.countries?.forEach(c => listOfIsoCodeToUpdate.push(c.phoneData?.isoCode));
      });

      const data = await smsProviderService.getAllBulkgatePrices(listOfIsoCodeToUpdate);
      updateMapOfIsoCodeAndEurPriceOfProvider(data, SMS_PROVIDER.BULKGATE);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = (event: any) => {
    event.preventDefault();
    props.onNext();
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={calculateColumnWidth(true)} className={classes.boldText + ' ' + classes.dashedBorderBottom}>
          Country
        </Grid>
        {LIST_OF_SMS_PROVIDERS.map(p => (
          <Grid
            item
            xs={calculateColumnWidth(false)}
            key={p + '-title'}
            className={classes.boldText + ' ' + classes.dashedBorderBottom}
          >
            {p}
          </Grid>
        ))}

        {continents?.map(continent => (
          <>
            <Grid item xs={calculateColumnWidth(true)} className={classes.boldText}>
              {continent.name}
            </Grid>
            {LIST_OF_SMS_PROVIDERS.map(p => (
              <Grid item xs={calculateColumnWidth(false)} key={p + '-empty'}></Grid>
            ))}

            {continent.countries.map(countryData => (
              <ProviderPriceInEurInputRow
                key={countryData.phoneData?.isoCode}
                eurPriceOfProviderList={props?.mapOfIsoCodeAndEurPriceOfProvider?.get(countryData.phoneData?.isoCode)}
                countryData={countryData}
                onDataChange={(data: EurPriceOfProvider[]) =>
                  providerPriceChangeHandler(countryData.phoneData?.isoCode, data)
                }
              ></ProviderPriceInEurInputRow>
            ))}
          </>
        ))}

        <Grid item xs={calculateColumnWidth(true)}></Grid>
        {LIST_OF_SMS_PROVIDERS.map(p => (
          <Grid item xs={calculateColumnWidth(false)} key={p}>
            {p === SMS_PROVIDER.GATEWAY_API ? (
              <>
                <a href="https://gatewayapi.com/pricing/#pricing" target="_blank">
                  Show price list
                </a>
                <Button
                  style={{ marginTop: '10px' }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="primary"
                  disabled={isLoading}
                  onClick={autoFillGatewayApiPrices}
                >
                  Auto fill
                </Button>
              </>
            ) : (
              <></>
            )}
            {p === SMS_PROVIDER.BULKGATE ? (
              <>
                <a href="https://portal.bulkgate.com/sms-price/list" target="_blank">
                  Show price list
                </a>
                <Button
                  style={{ marginTop: '10px' }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="primary"
                  disabled={isLoading}
                  onClick={autoFillBulkgatePrices}
                >
                  Auto fill
                </Button>
              </>
            ) : (
              <></>
            )}
            {/*            {p === SMS_PROVIDER.TWILLIO ? (
              <a href="https://www.twilio.com/en-us/sms/pricing/us" target="_blank">
                Show price list
              </a>
            ) : (
              <></>
            )}*/}
          </Grid>
        ))}
      </Grid>

      {isLoading ? <LinearProgress /> : <></>}

      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={isLoading} onClick={() => props.onBack()}>
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading} onClick={submit}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
