import { NumberUtils } from './number.utils';
import { EXTRA_FEE } from '../../models/consts/ExtraFee.const';
import { CommunicationUnitPackage } from '../../models/interfaces/CommunicationUnitPackage.interface';
import { CommunicationUnitPrice } from '../../models/interfaces/CommunicationUnitPrice.interface';
import { COMMUNICATION_WAY } from '../../models/enums/CommunicationWay.enum';
import { DEFAULT_CREDIT_PRICE_PER_EMAIL } from '../../models/consts/Other.const';

export class CreditPriceCalculatorUtils {
  private static readonly CALCULATION_FACTOR_A = 46.315888;
  private static readonly CALCULATION_FACTOR_B = -626.31777;
  private static readonly TOTAL_CREDIT_OF_THE_SMALLEST_CREDIT_PACKAGE = 3000;
  private static readonly EUR_PRICE_OF_THE_SMALLEST_CREDIT_PACKAGE = 30;

  public static calculateCreditPricePerSms(eurPricePerSms: number): number {
    if (!eurPricePerSms || eurPricePerSms < 0) {
      return null;
    }

    const totalExtraFeeInPercentage = CreditPriceCalculatorUtils.getTotalExtraFeeInPercentage();
    const eurPricePerSmsWithExtraFee = (100 * eurPricePerSms) / (100 - totalExtraFeeInPercentage);

    const totalEurCostOfTheSmallestCreditPackageForProvider =
      ((this.CALCULATION_FACTOR_A * this.EUR_PRICE_OF_THE_SMALLEST_CREDIT_PACKAGE + this.CALCULATION_FACTOR_B) *
        eurPricePerSmsWithExtraFee) /
      (1 + this.CALCULATION_FACTOR_A * eurPricePerSmsWithExtraFee);
    const maxNumberOfSmsCanBeSentForTheSmallestCreditPackage =
      totalEurCostOfTheSmallestCreditPackageForProvider / eurPricePerSmsWithExtraFee;
    const creditPricePerSmsOfTheSmallestCreditPackage =
      this.TOTAL_CREDIT_OF_THE_SMALLEST_CREDIT_PACKAGE / maxNumberOfSmsCanBeSentForTheSmallestCreditPackage;

    return NumberUtils.roundUpNumber(creditPricePerSmsOfTheSmallestCreditPackage, 2);
  }

  public static calculateEuroPriceOfEmailAndSmsForCreditPackage(
    creditPackage: CommunicationUnitPackage,
    creditUnitPrices: CommunicationUnitPrice[]
  ): CommunicationUnitPackage {
    const euroPricePerCredit = creditPackage.balance ? creditPackage.price / creditPackage.balance : 0;

    if (euroPricePerCredit) {
      // only 1 credit price of email in system
      const creditPriceOfEmail =
        creditUnitPrices?.find(
          p => p.countryCode === creditPackage?.countryCode && p.communicationWay === COMMUNICATION_WAY.EMAIL
        )?.price || DEFAULT_CREDIT_PRICE_PER_EMAIL;
      creditPackage.emailPrice = NumberUtils.roundUpNumber(
        this.calculateEuroPrice(creditPriceOfEmail, euroPricePerCredit),
        4
      );

      const creditPriceOfSms =
        creditUnitPrices?.find(
          p =>
            p.countryCode === creditPackage?.countryCode &&
            p.provider === creditPackage?.provider &&
            p.communicationWay === COMMUNICATION_WAY.SMS
        )?.price || 0;
      creditPackage.smsPrice = NumberUtils.roundUpNumber(
        this.calculateEuroPrice(creditPriceOfSms, euroPricePerCredit),
        4
      );
    }

    return creditPackage;
  }

  private static calculateEuroPrice(creditPrice: number, euroPricePerCredit: number): number {
    return creditPrice * euroPricePerCredit;
  }

  private static getTotalExtraFeeInPercentage(): number {
    return EXTRA_FEE.reduce((a, b) => a + b, 0);
  }
}
