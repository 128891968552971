export const DEFAULT_CATEGORY_NAME = '__default__';

export const DEFAULT_CREDIT_PRICE_PER_EMAIL = 4;

export const SERVICE_TAG_VARIANTS_DELIMITER = '%';
export const SERVICE_TAG_DELIMITER = ',';

export const LAST_BO_ID_WITH_OLD_PRO_PRICE: number = 90; // Old price: PRO fee = 18.90 EURO, TW Connect = free, Appointment fee = free
export const NEW_PRO_FEE = 30;
export const NEW_TW_CONNECT_FEE = 20;
export const OLD_PRO_FEE = 18.95;
